<template>
  <v-app>
    <v-theme-provider light>
      <v-navigation-drawer v-model="drawer" app bottom :temporary="false" disable-resize-watcher>
        <v-list nav dense the>
          <v-list-item>
            <v-btn v-if="!!account" block large rounded outlined @click="logoutState = !logoutState">
              {{ shortAccount }} <v-icon small class="ml-2">mdi-content-copy</v-icon></v-btn
            >
            <v-btn block large rounded outlined v-else @click="this.connectMetamask" :loading="connectingWallet"
              >Connect Wallet</v-btn
            >
          </v-list-item>
          <v-list-item>
            <router-link to="/locker" style="width: 100%">
              <v-btn block large rounded outlined>B-Locker</v-btn>
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link to="/bulkSender" style="width: 100%">
              <v-btn block large rounded outlined>B-Bulksender</v-btn>
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link to="/claimer" style="width: 100%">
              <v-btn block large rounded outlined>B-MultiClaimer</v-btn>
            </router-link>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-theme-provider>
    <v-app-bar app flat>
      <v-container :class="$vuetify.breakpoint.xs ? 'px-2' : 'px-6'">
        <div class="d-flex align-center">
          <v-menu offset-y class="secondary lighten-3" v-if="$vuetify.breakpoint.smAndUp">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text elevation="0" active-class="no-active">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <router-link to="/locker">
                <v-list-item>
                  <v-list-item-title>B-Locker</v-list-item-title>
                </v-list-item>
              </router-link>
              <router-link to="/bulksender">
                <v-list-item>
                  <v-list-item-title>B-BulkSender</v-list-item-title>
                </v-list-item>
              </router-link>
              <router-link to="/claimer">
                <v-list-item>
                  <v-list-item-title>B-MultiClaimer</v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list>
          </v-menu>
          <router-link to="/">
            <v-img
              style="cursor: pointer"
              alt="Vuetify Logo"
              class="shrink mr-2"
              max-width="130"
              contain
              :src="
                $vuetify.breakpoint.xs ? require(`@/assets/logo.dark.svg`) : require(`@/assets/logo-with-name.dark.svg`)
              "
            />
          </router-link>
          <span class="font-weight-bold mr-auto" v-if="$route.meta.title === 'home'">| B - Tool</span>
          <span class="font-weight-bold mr-auto" v-if="$route.meta.title === 'locker'">| Token Locker</span>
          <span class="font-weight-bold mr-auto" v-if="$route.meta.title === 'bulkSend'">| BulkSender</span>
          <span class="font-weight-bold mr-auto" v-if="$route.meta.title === 'claimer'">| MultiClaimer</span>
          <div class="d-none d-sm-flex align-center">
            <v-btn
              v-if="!!account"
              depressed
              rounded
              color="primary"
              large
              outlined
              class="mr-4"
              @click="logoutState = !logoutState"
            >
              {{ shortAccount }}
              <v-icon small class="ml-2">mdi-content-copy</v-icon>
            </v-btn>
            <v-btn
              v-else
              depressed
              rounded
              color="primary"
              class="mr-4"
              @click="connectWallet"
              :loading="connectingWallet"
              >Connect Wallet</v-btn
            >
          </div>
          <v-app-bar-nav-icon class="d-flex d-sm-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </div>
      </v-container>
    </v-app-bar>
    <v-main class="secondary lighten-1">
      <PluginLoading />
      <PluginAlert />
      <LogoutDialog :state="logoutState" />
      <div class="d-flex flex-column justify-center align-center pa-0 mt-4">
        <router-view />
      </div>
    </v-main>

    <!-- <v-footer app></v-footer> -->
  </v-app>
</template>
<script>
import PluginAlert from '@/components/plugins/PluginAlert'
import PluginLoading from '@/components/plugins/PluginLoading'
import { mapState, mapActions } from 'vuex'
import LogoutDialog from '@/components/dialogs/LogoutDialog'
export default {
  components: {
    PluginAlert,
    PluginLoading,
    LogoutDialog,
  },
  data() {
    return {
      drawer: false,
      logoutState: false,
    }
  },
  computed: {
    ...mapState('auth', ['account', 'connectingWallet']),
    shortAccount() {
      if (!this.account) return ''
      return this.account.substr(0, 5) + '...' + this.account.substr(this.account.length - 3)
    },
  },

  methods: {
    ...mapActions('auth', ['connectMetamask']),
    ...mapActions('bulkSendPrepare', ['resetData']),
    async connectWallet() {
      try {
        await this.connectMetamask()
        switch (this.$route.meta.title) {
          case 'locker':
            this.$router.push('selectLockToken')
            break
          case 'bulksend':
            this.$router.push('prepare')
            break
          case 'claimer':
            this.$router.push('pools')
            break
        }
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>
<style>
.v-list-item {
  padding-left: 10px !important;
  cursor: pointer;
}
.v-list-item__title {
  text-align: left;
}
.v-menu__content {
  background: var(--v-secondary-ligten3) !important;
  border: 1px solid #5a5a5a !important;
  margin-top: 8px;
}
</style>
