<template>
  <v-dialog v-model="dialog" :minWidth="$vuetify.breakpoint.smAndUp ? '520px' : ''" max-width="520px" persistent>
    <div class="white--text text-left" :class="$vuetify.breakpoint.smAndUp ? 'pa-6' : 'pa-2'">
      <div class="d-flex justify-space-between mb-8 text-medium">
        <h4 class="align-self-center text-medium">Account</h4>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </div>
      <v-sheet rounded="xl" class="pa-6 secondary-lighten-3">
        <p class="text-body-1">Connect with MetaMask</p>
        <div class="primary--text text-h5 mb-6">{{ account | shortAccount }}</div>

        <div class="d-flex mt-4 flex-wrap">
          <v-btn text class="text-caption text-left pl-0 mr-10" @click="copyText"
            >Copy Address <v-icon small class="ml-2">mdi-content-copy</v-icon></v-btn
          >
          <v-btn text class="text-caption pl-0" :href="getBscScanUrl" target="_blank"
            >View on BscScan <v-icon small class="ml-2">mdi-arrow-top-right</v-icon></v-btn
          >
        </div>
      </v-sheet>
    </div>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    state: Boolean,
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapState('auth', ['account', 'provider']),
    getBscScanUrl() {
      return this.provider.chainId === '0x38'
        ? `https://bscscan.com/address/${this.account}`
        : this.provider.chainId === '0x61'
        ? `https://testnet.bscscan.com/address/${this.account}`
        : ''
    },
  },
  methods: {
    copyText() {
      navigator.clipboard.writeText(this.account)
    },
  },
  filters: {
    shortAccount(account) {
      return !account ? '' : account.substr(0, 12) + '...' + account.substr(account.length - 4)
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = true
    },
  },
}
</script>
<style scoped>
.v-sheet {
  background-color: transparent !important;
  border: thin solid #f7ce2a !important;
}
</style>
